import { useMemo } from "react";
import { useSelector, useDispatch, ACTIONS } from "utils/StateProvider";
import useTableSort from "utils/tables/useTableSort";

import { PinColumn } from "./columns";

const archiveParam = "filter[isArchived]";

const useDocumentsTableConfig = (tableName, queryParams, columns) => {
  const currentTableSort = useSelector(state => state?.tableSort[tableName]);
  const dispatch = useDispatch();
  const onSort = useTableSort(ACTIONS, dispatch, tableName);

  const sortState = useMemo(() => {
    let sort = [];
    if (columns.find(col => col === PinColumn)) {
      sort.push({ id: "isPinned", desc: true });
    }
    if (queryParams?.[archiveParam] !== false) {
      sort.push({ id: "latestVersionOrSelf" }, { id: "updatedOn", desc: true });
    }
    if (currentTableSort?.length) {
      sort.push(...currentTableSort);
    }
    return sort;
  }, [currentTableSort, queryParams, columns]);

  const sort = useMemo(() => {
    if (sortState.length) {
      return sortState.map(({ desc, id }) => (desc ? `-${id}` : id)).join(",");
    }
    return undefined;
  }, [sortState]);

  return {
    sortState,
    currentTableSort,
    queryParams: { ...queryParams, sort },
    onSort,
  };
};

export default useDocumentsTableConfig;
