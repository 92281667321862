import PropTypes from "prop-types";
import styled from "styled-components";
import { DateTime } from "luxon";
import {
  YukaThemeProvider,
  Card,
  CardStyles,
  Fonts,
  ComponentGroup,
  HyperLink,
  ColorPalette,
} from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import { DEAL_FILE_STATUS_INFORMATION_UPDATED } from "utils/constants";

import DealFileStatusBadge from "./DealFileStatusBadge";

const StyledBadgeContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledRerequest = styled(Fonts.Caption2theme50)`
  border-top: 1px solid ${ColorPalette.white15};
  padding-top: 16px;
`;

const DEFAULT_TEXT = "Any feedback from Compliance will be shown here.";

const ConnectionComplianceStatusCard = ({ connection, includeRerequest }) => {
  // Note: the link for this needs to show inline, but `button` elements always render inline-block
  // Workaround: specify url="#" which gets us an `a` from yuka
  const { onSubmit } = useWrite(QUERY_KEYS.CONNECTIONS.detail(connection.tradeId));
  const requestReview = () => {
    onSubmit({
      tradeId: connection.tradeId,
      apiType: connection.apiType,
      dealFileStatus: DEAL_FILE_STATUS_INFORMATION_UPDATED,
    });
  };

  return (
    <YukaThemeProvider theme={{ surfaceLevel: 2 }}>
      <Card cardStyle={CardStyles.PADDED}>
        <ComponentGroup>
          <StyledBadgeContainer>
            <DealFileStatusBadge connection={connection} />
            <Fonts.Caption2theme50>
              {DateTime.fromISO(connection.lastDealFileActionOn).toLocaleString(
                DateTime.DATE_SHORT
              )}
            </Fonts.Caption2theme50>
          </StyledBadgeContainer>
          {connection.dealFileNotes ? (
            <Fonts.Body1theme80 as="p">{connection.dealFileNotes}</Fonts.Body1theme80>
          ) : (
            <Fonts.Body1theme50 as="p">{DEFAULT_TEXT}</Fonts.Body1theme50>
          )}
          {includeRerequest && (
            <StyledRerequest as="p">
              When you have addressed the feedback,&nbsp;
              <HyperLink url="#" onClick={requestReview}>
                Send for Compliance Review
              </HyperLink>
            </StyledRerequest>
          )}
        </ComponentGroup>
      </Card>
    </YukaThemeProvider>
  );
};

ConnectionComplianceStatusCard.propTypes = {
  includeRerequest: PropTypes.bool,
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
    apiType: PropTypes.string,
    dealFileNotes: PropTypes.string,
    lastDealFileActionOn: PropTypes.string,
  }).isRequired,
};

ConnectionComplianceStatusCard.defaultProps = {
  includeRerequest: false,
};

export default ConnectionComplianceStatusCard;
