import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  YukaThemeProvider,
  Button,
  ButtonStyles,
  FilePlusIcon,
  SendIcon,
  XCloseIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  useAnchoredMenu,
} from "yuka";

import CancelOnboardingRequestForm from "forms/CancelOnboardingRequestForm";
import ResendOnboardingRequestForm from "forms/ResendOnboardingRequestForm";

const RESEND_MODAL = "RESEND_MODAL";
const CANCEL_MODAL = "CANCEL_MODAL";

const OnboardingRequestInfo = ({ id, latestOnboardingRequest }) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState("");

  const startOnboardFlow = id => {
    navigate(`/clients/onboarding/${id}`);
  };

  const [dropdown, dropdownRef, toggleDropdown] = useAnchoredMenu({
    menuItems: [
      {
        text: "Resend Onboarding Request",
        icon: SendIcon,
        onClick: () => setModalOpen(RESEND_MODAL),
      },
      {
        text: "Cancel Onboarding Request",
        icon: XCloseIcon,
        onClick: () => setModalOpen(CANCEL_MODAL),
      },
    ],
  });

  if (latestOnboardingRequest?.completedOn || !latestOnboardingRequest) {
    return (
      <Button
        buttonStyle={ButtonStyles.KEY_ACTION}
        trailingIcon={FilePlusIcon}
        onClick={() => startOnboardFlow(id)}
      >
        Onboard Client
      </Button>
    );
  }

  return (
    <>
      <Button
        ref={dropdownRef}
        onClick={toggleDropdown}
        trailingIcon={dropdown ? ChevronUpIcon : ChevronDownIcon}
        buttonStyle={ButtonStyles.KEY_ACTION}
        selected={Boolean(dropdown)}
      >
        Optional Onboard Actions
      </Button>
      {isModalOpen === CANCEL_MODAL && (
        <CancelOnboardingRequestForm
          latestOnboardingRequest={latestOnboardingRequest}
          id={id}
          onClose={() => setModalOpen("")}
        />
      )}
      {isModalOpen === RESEND_MODAL && (
        <ResendOnboardingRequestForm
          latestOnboardingRequest={latestOnboardingRequest}
          id={id}
          onClose={() => setModalOpen("")}
        />
      )}
      <YukaThemeProvider theme={{ surfaceLevel: 2 }}>{dropdown}</YukaThemeProvider>
    </>
  );
};

OnboardingRequestInfo.propTypes = {
  id: PropTypes.string.isRequired,
  latestOnboardingRequest: PropTypes.shape({
    apiId: PropTypes.string,
    email: PropTypes.string,
    completedOn: PropTypes.string,
    sentOn: PropTypes.string,
  }),
};

export default OnboardingRequestInfo;
