import { useCallback, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import arrayMutators from "final-form-arrays";
import { FormModal, FinalFormField } from "yuka";

import { required } from "utils/form/inputValidations";
import { fetchQuery, getRelatedId, useWrite, useDelete, QUERY_KEYS, DataTypes } from "api";
import { InputRow } from "forms/FormComponents";
import LoadingSpinner from "utils/LoadingSpinner";
import { mpTrack } from "utils/mixpanel";

import ClientTypeField from "./ClientTypeField";
import ClientInfoFieldSet from "./ClientInfoFieldSet";
import { VALID_UUID_REGEX } from "./constants";

const loadSourceOptions = search =>
  fetchQuery(
    QUERY_KEYS.SOURCES.list({
      "filter[search]": search,
      "filter[has_no_source_profile]": true,
    })
  ).then(sources =>
    sources.map(source => ({
      value: source.apiId,
      label: `${source.name} (${source.category || "Unknown"} #${source.searchId})`,
    }))
  );

const AddClientForm = ({ closeModal, initialSourceType, userType, zxSource }) => {
  const [sourceProfile, setSourceProfile] = useState(null);
  const [shouldDeleteOnCancel, setShouldDeleteOnCancel] = useState(false);
  const [isZxSourceSelected, setIsZxSourceSelected] = useState(false);
  const navigate = useNavigate();

  // If we cancel out after looking up and creating a new profile, we should delete it because
  // the user never saved it
  const { onSubmit: apiDelete } = useDelete(
    QUERY_KEYS.CLIENT_PROFILES.detail(getRelatedId(sourceProfile?.source)),
    { silent: true }
  );

  const handleSourceSelect = useCallback(
    async sourceId => {
      if (shouldDeleteOnCancel) {
        await apiDelete().then(() => {
          setShouldDeleteOnCancel(() => false);
        });
      }
      if (!sourceId) {
        return Promise.resolve().then(() => {
          setSourceProfile(null);
          setShouldDeleteOnCancel(() => false);
        });
      }
      return fetchQuery(QUERY_KEYS.SOURCES.detail(sourceId, ["lookup"])).then(source => {
        setSourceProfile(source);
        const createdOn = DateTime.fromISO(source.createdOn);
        const diff = DateTime.now().diff(createdOn, "seconds");
        // If we just created it within a reasonable threshold, we assume it didn't exist outside the
        // lookup, and so we should remove it to keep data clean afterwards
        setShouldDeleteOnCancel(() => diff.seconds < 30);
      });
    },
    [apiDelete, shouldDeleteOnCancel]
  );

  useEffect(() => {
    if (zxSource && !isZxSourceSelected) {
      handleSourceSelect(zxSource.apiId);
      setIsZxSourceSelected(true);
    }
  }, [handleSourceSelect, zxSource, isZxSourceSelected]);

  const handleCancel = () => {
    if (shouldDeleteOnCancel) {
      apiDelete();
    }
    closeModal();
  };

  // We allow the user to create new sources as part of this process
  const { onSubmit: createSource } = useWrite(QUERY_KEYS.SOURCES.list(), { silent: true });

  // Get query key for main profile submission
  const queryKey = sourceProfile
    ? QUERY_KEYS.CLIENT_PROFILES.detail(getRelatedId(sourceProfile.source))
    : QUERY_KEYS.CLIENT_PROFILES.list();

  const { onSubmit } = useWrite(queryKey);

  const wrappedOnSubmit = async (values, ...params) => {
    const cleanedValues = {
      ...values,
      apiType: DataTypes.CLIENT_PROFILES,
      zxRepresentatives: values.zxRepresentatives
        ? values.zxRepresentatives.map(id => ({ apiId: id, apiType: DataTypes.MARKET_OPERATORS }))
        : [],
      source: { ...values.source, apiType: DataTypes.CLIENTS },
    };
    const isNewSource = !values.source.apiId.match(VALID_UUID_REGEX);

    if (isNewSource) {
      const createdSource = await createSource({
        apiType: DataTypes.CLIENTS,
        name: values.source.apiId,
        category: "",
      });
      cleanedValues.source.apiId = createdSource.data.id;
    }

    if (sourceProfile) {
      cleanedValues.apiId = getRelatedId(sourceProfile.source);
    }
    return onSubmit(cleanedValues, ...params).then(response => {
      mpTrack("create client profile");
      closeModal();
      if (!userType) {
        navigate(`/clients/${response.data.relationships.source.data.id}/`);
      }
    });
  };

  // When we select a source for the profile to link to, attempt to autofill other fields based on
  // it. `initialValues` and the request triggered in `handleSourceSelect` manage this for us.
  const initialValues = useMemo(
    () => ({
      "user-type": userType,
      sourceType: initialSourceType,
      source: zxSource ? { apiId: zxSource.apiId } : {},
      phone: sourceProfile?.phone,
      email: sourceProfile?.email,
      address: sourceProfile?.address,
      zxRepresentatives: sourceProfile?.zxRepresentatives.map(getRelatedId),
    }),
    [sourceProfile, initialSourceType, userType, zxSource]
  );

  return (
    <Form
      onSubmit={wrappedOnSubmit}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      keepDirtyOnReinitialize
    >
      {({ submitting, handleSubmit, values: { sourceType } }) => (
        <FormModal
          onSubmit={handleSubmit}
          submitText="Create"
          onClose={handleCancel}
          title="Add New Client"
          size="large"
          requireDirtyForSubmit={false}
        >
          {submitting && <LoadingSpinner />}
          <InputRow>
            <FinalFormField
              type="custom-block"
              component={ClientTypeField}
              name="sourceType"
              validate={[required]}
              initialUserType={userType}
            />
          </InputRow>
          {sourceType && (
            <InputRow>
              <FinalFormField
                type="autocomplete"
                label="Name"
                placeholder="Enter name"
                options={
                  zxSource
                    ? [
                        {
                          value: zxSource.apiId,
                          label: `${zxSource.name} (${zxSource.category || "Unknown"} #${
                            zxSource.searchId
                          })`,
                        },
                      ]
                    : []
                }
                loadOptions={loadSourceOptions}
                name="source.apiId"
                popup
                onChange={handleSourceSelect}
              />
            </InputRow>
          )}
          <ClientInfoFieldSet />
        </FormModal>
      )}
    </Form>
  );
};

AddClientForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  initialSourceType: PropTypes.string,
  userType: PropTypes.string,
  zxSource: PropTypes.shape({
    apiId: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    searchId: PropTypes.number,
  }),
};

AddClientForm.defaultProps = {
  initialSourceType: null,
  userType: null,
  zxSource: null,
};

export default AddClientForm;
